<template>
  <v-form ref="form" v-model="valid">
    <v-container style="justify-content: center; display: flex">
      <v-col style="max-width: 500px">
        <v-col>
          <v-text-field
            v-model="userData.name"
            :rules="nameRules"
            label="Nombre"
            required
            ><v-icon slot="prepend"> mdi-account </v-icon></v-text-field
          >
        </v-col>

        <v-col>
          <v-text-field
            v-model="userData.email"
            :rules="emailRules"
            label="E-mail"
            required
            ><v-icon slot="prepend"> mdi-at </v-icon></v-text-field
          >
        </v-col>

        <v-col>
          <v-text-field
            v-model="userData.phoneNumber"
            :rules="phoneRules"
            :counter="9"
            prefix="+56"
            label="Telefono"
            required
            ><v-icon slot="prepend"> mdi-phone </v-icon></v-text-field
          >
        </v-col>

        <v-btn color="#0080B3" @click="validate()"> Siguiente </v-btn>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "InitForm",
  props: ["userData"],
  data: () => ({
    valid: false,
    nameRules: [(v) => !!v || "El nombre es requerido"],
    emailRules: [
      (v) => !!v || "El correo electrónico es equerido",
      (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("next-step");
      }
    },
  },
};
</script>